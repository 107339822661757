<template>
  <div
    style="height: 85vh; overflow: hidden; overflow-y: scroll; width: 100%;position: relative;"
  >
    <el-tabs v-model="activeName" @tab-click="changeTags(activeName)">
      <el-tab-pane label="全部" name="1"></el-tab-pane>
      <el-tab-pane label="进行中" name="2"></el-tab-pane>
      <el-tab-pane label="已完成" name="3"></el-tab-pane>
      <el-tab-pane name="4">
        <span slot="label">
          <el-badge
            :value="rejected == 0 ? '' : rejected"
            class="item"
            :max="99"
            >被驳回</el-badge
          >
        </span>
      </el-tab-pane>
    </el-tabs>
    <div class="flex bewteen aline_center center wrap" style="padding: 20px 0">
      <el-form ref="form" :model="form" label-width="80px" class="flex">
        <div class="flex aline_center center wrap">
          <div style="width:30%;">
            <el-form-item label="搜索：" label-width="120px">
              <el-input
                v-model="form.keyword"
                placeholder="请输入产品名称"
              ></el-input>
            </el-form-item>
          </div>
          <div style="width:30%;">
            <el-form-item label="拟融资额度（万元）：" label-width="180px">
              <el-input
                v-model="form.min_quota"
                placeholder="请输入数字"
              ></el-input>
              <span style="margin: 0 20px">至</span>
              <el-input
                v-model="form.max_quota"
                placeholder="请输入数字"
              ></el-input>
            </el-form-item>
          </div>
          <div style="width:40%;">
            <el-form-item label="利率范围（%）：" label-width="180px">
              <el-input
                v-model="form.min_rate"
                placeholder="请输入数字"
              ></el-input>
              <span style="margin: 0 20px">至</span>
              <el-input
                v-model="form.max_rate"
                placeholder="请输入数字"
              ></el-input>
            </el-form-item>
          </div>
          <div style="width:30%">
            <el-form-item label="政策性产品：" label-width="120px">
              <el-select
                v-model="form.policy"
                placeholder="请选择"
                clearable
                @clear="policy = ''"
                style="width:100%"
              >
                <el-option
                  :value="item.value"
                  v-for="(item, index) in opaction1"
                  :key="index"
                  @click.native="setPolicy(item)"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="width:30%;">
            <el-form-item label="融资期限（月）：" label-width="180px">
              <el-input
                v-model="form.min_term"
                placeholder="请输入数字"
              ></el-input>
              <span style="margin: 0 20px">至</span>
              <el-input
                v-model="form.max_term"
                placeholder="请输入数字"
              ></el-input>
            </el-form-item>
          </div>
          <div style="width:40%;">
            <el-form-item label="申请时间：" label-width="180px">
              <el-col :span="11">
                <el-date-picker
                  clearable
                  type="date"
                  placeholder="选择日期"
                  v-model="form.startTime"
                  style="width: 100%"
                  @input="clearStartTime"
                ></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-date-picker
                  clearable
                  type="date"
                  placeholder="选择日期"
                  v-model="form.endTime"
                  style="width: 100%"
                  @input="clearEndTime"
                ></el-date-picker>
              </el-col>
            </el-form-item>
          </div>

          <div style="width:30%;">
            <el-form-item label="状态：" label-width="120px">
              <el-select
                v-model="form.status"
                placeholder="请选择"
                clearable
                @clear="status = ''"
                style="width:100%;"
                :disabled="disabled"
              >
                <el-option
                  :value="item.value"
                  v-for="(item, index) in opaction2"
                  :key="index"
                  @click.native="setStatus(item)"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <el-button type="primary" @click.native="searchData"
                >搜索</el-button
              >
              <el-button type="primary" @click.native="toFinancialproducts"
                >更多产品</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <el-table
      :data="listsItem"
      style="width: 100%"
      :header-cell-style="{ background: '#e5f3ff', color: '#1A7FD7' }"
    >
      <el-table-column prop="mark" label="编号"> </el-table-column>
      <el-table-column prop="name" label="产品名称"> </el-table-column>
      <el-table-column prop="fin_name" label="所属机构"> </el-table-column>
      <el-table-column prop="quota" label="拟融资额度（万元）" class-name="red">
      </el-table-column>
      <el-table-column prop="moneyTime" label="年利率（%）" class-name="blue">
        <template slot-scope="scope">
          <span>{{ scope.row.min_rate }}- {{ scope.row.max_rate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="term" label="融资期限（月）" class-name="blue">
      </el-table-column>
      <el-table-column prop="cate_name" label="产品类型"> </el-table-column>
      <el-table-column prop="status" label="政策型产品">
        <template slot-scope="scope">
          <span v-if="scope.row.is_policy == 0">否</span>
          <span v-if="scope.row.is_policy == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="accept_quota"
        label="授信额度（万）"
        class-name="blue"
      >
      </el-table-column>
      <el-table-column prop="create_time" label="申请时间"></el-table-column>
      <el-table-column prop="address" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1"> 待审核</span>
          <span v-if="scope.row.status == 2"> 已通过</span>
          <span v-if="scope.row.status == 3"> 未通过</span>
          <span v-if="scope.row.status == 4"> 被驳回</span>
          <span v-if="scope.row.status == 5"> 已授信</span>
          <span v-if="scope.row.status == 6"> 已完成</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <span class="success_font" @click="toAgencydetails(scope.row.fina_id)"
            >查看对接机构</span
          >
          <span
            v-if="scope.row.status == 4 || scope.row.status == 3"
            class="not_font"
            @click="lookWhy(scope.row.comment)"
            >查看原因</span
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="financialproducts_list_reportscreen">
      <div class="financialproducts_list_footer">
        <div class="financialproducts_list_mar">
          <app-mypaginations
            :pagSize="paginations"
            :allData="goodList"
            @setHandleCurrent="handleCurrent"
            @setHandleSize="handleSize"
            @setPage="setPage"
            @setSize="setSize"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyPaginations from '@/components/MyPaginations/mypaginations.vue';
export default {
  data() {
    return {
      menu: 1,
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: 'total, sizes, prev, pager, next, jumper', // 翻页属性
      },
      disabled: false,
      form: {
        keyword: '',
        min_quota: '',
        max_quota: '',
        min_term: '',
        max_term: '',
        min_rate: '',
        max_rate: '',
        startTime: '',
        endTime: '',
        policy: '',
        status: '',
      },
      opaction1: [
        {
          value: '是',
          id: 1,
        },
        {
          value: '否',
          id: 2,
        },
      ],
      opaction2: [
        {
          value: '待审核',
          id: 1,
        },
        {
          value: '审核未通过',
          id: 2,
        },
        {
          value: '已审核',
          id: 3,
        },
        {
          value: '已驳回',
          id: 4,
        },
        {
          value: '已授信',
          id: 5,
        },
        {
          value: '已完成',
          id: 6,
        },
      ],
      policy: '',
      status: '',
      goodList: [],
      listsItem: [],
      count: 0,
      activeName: '1',
      rejected: 0
    };
  },
  methods: {
    lookWhy(comment) {
      this.$confirm(comment, '原因', {
        showConfirmButton: false,
        showCancelButton: false,
        closeOnClickModal: true,
      });
    },
    toFinancialproducts() {
      this.$router.push('/financialproducts');
    },
    toAgencydetails(id) {
      // console.log(id)
      this.$router.push({ path: '/agencydetails', query: { ids: id } });
    },
    changeTags(num) {
      this.menu = num;

      this.form.status = '';
      this.status = '';

      if (this.menu == 4) {
        this.form.status = '已驳回';
        this.status = 4;
      }

      this.searchData();
    },
    clearEndTime(value) {
      if (value == null) {
        this.form.endTime = '';
      }
    },
    clearStartTime(value) {
      if (value == null) {
        this.form.startTime = '';
      }
    },
    setPolicy(item) {
      this.policy = item.id;
    },
    setStatus(item) {
      this.status = item.id;
    },
    setPage(data) {
      this.paginations.page_index = data;
    },
    setSize(data) {
      this.paginations.page_index = 1;
      this.paginations.page_size = data;
    },
    handleCurrent(item) {
      this.listsItem = item;
    },
    handleSize(item) {
      this.listsItem = item;
    },
    //重置分页
    resetPaginations() {
      // 总页数
      this.paginations.total = this.count;
      // 设置默认分页数据
      this.listsItem = this.goodList.filter((item, index) => {
        return index < this.paginations.page_size;
      });
    },
    searchData() {
      this.paginations.page_index = 1;

      this.comAskApi();
    },
    comAskApi() {
      this.disabled = this.menu == 4 ? true : false;
      let data = {
        token: localStorage.eleToken ? localStorage.eleToken : '',
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        keyword: this.form.keyword,
        min_quota: this.form.min_quota,
        max_quota: this.form.max_quota,
        min_term: this.form.min_term,
        max_term: this.form.max_term,
        min_rate: this.form.min_rate,
        max_rate: this.form.max_rate,
        policy: this.policy,
        startTime:
          this.form.startTime == '' ? '' : this.$turnTime(this.form.startTime),
        endTime:
          this.form.endTime == '' ? '' : this.$turnTime(this.form.endTime),
        status: this.status,
        group: this.menu == 4 ? 1 : this.menu,
      };

      this.$post('/com_ask', data).then((res) => {
        this.goodList = res.data.result ? res.data.result : [];

        this.count = res.data.count;

        this.resetPaginations();
      });
    },
    comAskNumApi() {
      let data = {
        token: localStorage.eleToken ? localStorage.eleToken : '',
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        keyword: this.form.keyword,
        min_quota: this.form.min_quota,
        max_quota: this.form.max_quota,
        min_term: this.form.min_term,
        max_term: this.form.max_term,
        min_rate: this.form.min_rate,
        max_rate: this.form.max_rate,
        policy: this.policy,
        startTime:
          this.form.startTime == '' ? '' : this.$turnTime(this.form.startTime),
        endTime:
          this.form.endTime == '' ? '' : this.$turnTime(this.form.endTime),
        status: 4,
        group: 1,
      };

      this.$post('/com_ask', data).then((res) => {
        this.rejected = res.data.count;
        console.log('rejected', res.data);
      });
    },
  },
  mounted() {
    this.comAskApi();
    this.comAskNumApi();
  },
  components: {
    'app-mypaginations': MyPaginations,
  },
};
</script>

<style scoped>
.productlist_nav {
  width: 40px;
  height: 4px;
  background: #0d8eff;
  border-radius: 2px;
}

/deep/.el-form-item__content {
  display: flex;
}

/*分页*/
.financialproducts_list_reportscreen {
  position: absolute;
  bottom: 0.5%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.financialproducts_list_footer {
  min-width: 462px;
  height: 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.financialproducts_list_mar {
  width: 100%;
}
.el-pagination {
  display: flex;
}
.el-pagination .el-select .el-input {
  height: 100%;
}
.el-select {
  height: 100%;
}
.el-pagination__jump {
  display: flex !important;
}

/deep/.red {
  color: #ff9d00;
}
/deep/.blue {
  color: #0d8eff;
}

/deep/.el-tabs__nav-wrap::after {
  height: 1px;
}

/deep/.el-badge__content.is-fixed {
  top: 7px !important;
}
</style>
